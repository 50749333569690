import React, { Component } from "react";

class Header extends Component {
  layoutContainer = this.props.layoutContainer;

  render() {
    return (
      <header className="header w-full fixed pin-t pin-l pin-r z-5 white">
        <a
          href="javascript:;"
          className={`header__back fixed pin-t pin-r z-5 gpu flex-center cursor-pointer f2 ${
            this.layoutContainer.state.bioActive ? `active` : ``
          }`}
          onClick={this.layoutContainer.hideBio}
        >
          ←
        </a>

        <nav className="relative grid">
          <div className="grid__colend--9 grid__colend--md-8 grid__colend--sm-12 no-pointer">
            <h1 className="f2">Charl Laubscher</h1>
          </div>

          <div
            className={`header__nav grid__colend--3 grid__colend--md-4 bio-hideable ${
              this.layoutContainer.state.bioActive ? `bio-hide` : ``
            }`}
          >
            <h2
              className="header__about f2 cursor-pointer"
              onClick={this.layoutContainer.toggleBio}
            >
              <span className="no-select">About</span>
            </h2>
            <h2 className="f2 underline">
              <a
                href="https://loveandmoney.agency"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="no-select">Love + Money</span>
              </a>
            </h2>
            <h2 className="f2 underline">
              <a
                href="https://itsallfluff.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="no-select">Fluff</span>
              </a>
            </h2>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
