import React, { Component } from "react";

class Footer extends Component {
  layoutContainer = this.props.layoutContainer;

  render() {
    return (
      <footer
        className={`footer w-full fixed pin-b pin-l pin-r z-5 white bio-hideable ${
          this.layoutContainer.state.bioActive ? `bio-hide` : ``
        }`}
      >
        <nav className="footer__default relative grid">
          <div className="grid__colend--9 grid__colend--md-8">
            <h1 className="f2 underline">
              <a
                href="https://www.instagram.com/charliehoers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="no-select">@charliehoers</span>
              </a>
            </h1>
          </div>

          <div className="grid__colend--3 grid__colend--md-4">
            <h2 className="f2 underline">
              <a
                href="https://medium.com/@charliehoers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="no-select">Medium</span>
              </a>
            </h2>
          </div>
        </nav>

        <nav className="footer__touch relative grid">
          <ul className="grid__colend--12">
            <li>
              <a
                href="javascript:;"
                className="block f2"
                onClick={this.layoutContainer.showBio}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="https://loveandmoney.agency"
                target="_blank"
                rel="noopener noreferrer"
                className="block f2 underline"
              >
                Love + Money
              </a>
            </li>
            <li>
              <a
                href="https://itsallfluff.com"
                target="_blank"
                rel="noopener noreferrer"
                className="block f2 underline"
              >
                Fluff
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@charliehoers"
                target="_blank"
                rel="noopener noreferrer"
                className="block f2 underline"
              >
                Medium
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    );
  }
}

export default Footer;
