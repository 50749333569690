import { Container } from "unstated";

class LayoutContainer extends Container {
  state = {
    bioActive: false
  };

  hideBio = () => {
    if (!this.state.bioActive) {
      return;
    }

    this.setState({ bioActive: false });
  };

  showBio = () => {
    if (this.state.bioActive) {
      return;
    }

    this.setState({ bioActive: true });
  };

  toggleBio = () => {
    this.setState(prevState => ({
      bioActive: !prevState.bioActive
    }));
  };
}

export default LayoutContainer;
