import React, { Component } from "react";

class Mailer extends Component {
  layoutContainer = this.props.layoutContainer;

  render() {
    return (
      <section className="mailer full-fixed z-2 flex-center no-pointer white">
        <a
          href="mailto:charl@loveandmoney.agency"
          className={`home__mailto auto-pointer f1 underline bio-hideable ${
            this.layoutContainer.state.bioActive ? `bio-hide` : ``
          }`}
        >
          <span className="no-select">charl@loveandmoney.agency</span>
        </a>
      </section>
    );
  }
}

export default Mailer;
