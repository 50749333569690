import React, { Component } from "react";
import * as THREE from "three";
import { OBJLoader } from "three-obj-mtl-loader";

class Skull extends Component {
  state = {
    mouseX: -50,
    mouseY: -50,
    windowHalfX: 0,
    windowHalfY: 0
  };

  camera = null;

  material = null;

  renderer = null;

  scene = null;

  skullObject = null;

  texture = null;

  textureCount = 9;

  textureIndex = 0;

  textureLoader = null;

  //

  canvasContainer = React.createRef();

  layoutContainer = this.props.layoutContainer;

  componentDidMount() {
    this.textureLoader = new THREE.TextureLoader();

    this.textureLoader.load(
      `img/${this.textureIndex}.jpg`,
      texture => {
        this.texture = texture;
        this.init();
      },
      undefined,
      err => {
        console.error(`Error caught: `, err);
      }
    );
  }

  init = () => {
    this.setCamera();

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.canvasContainer.current.appendChild(this.renderer.domElement);

    //

    this.texture.mapping = THREE.SphericalReflectionMapping;

    this.material = new THREE.MeshBasicMaterial({
      envMap: this.texture
    });

    const manager = new THREE.LoadingManager();
    const loader = new OBJLoader(manager);

    loader.load(`obj/skull.obj`, object => {
      this.skullObject = object;

      this.skullObject.traverse(child => {
        if (child instanceof THREE.Mesh) {
          child.material = this.material;

          child.position.y = -3.75;
          child.position.z = 2;
          child.position.x = 0.55;
        }
      });

      this.scene.add(this.skullObject);

      this.animate();
    });

    //

    document.addEventListener(`mousemove`, this.onDocumentMouseMove, false);
    document.addEventListener(`touchmove`, this.onDocumentTouchMove, false);
    window.addEventListener(`resize`, this.onWindowResize, false);
  };

  onWindowResize = () => {
    this.setState({
      windowHalfX: window.innerWidth / 2,
      windowHalfY: window.innerHeight / 2
    });

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.setCamera();

    this.renderer.setSize(window.innerWidth, window.innerHeight);
  };

  onDocumentMouseMove = event => {
    this.setState(prevState => ({
      mouseX: (event.clientX - prevState.windowHalfX) / 2,
      mouseY: (event.clientY - prevState.windowHalfY) / 2
    }));
  };

  onDocumentTouchMove = event => {
    event.preventDefault();
    return false;
  };

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderCanvas();
  };

  renderCanvas = () => {
    if (this.skullObject) {
      this.skullObject.rotation.y += -this.state.mouseX * 0.00005;
      this.skullObject.rotation.x += -this.state.mouseY * 0.00005;
      this.skullObject.rotation.z += 0.0005;
    }

    this.renderer.render(this.scene, this.camera);
  };

  setCamera = () => {
    if (!this.camera) {
      this.camera = new THREE.PerspectiveCamera(
        400,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
    }

    if (this.isDesktop()) {
      this.camera.position.z = 15;
    } else {
      this.camera.position.z = 25;
    }
  };

  shuffleTexture = () => {
    this.textureIndex += 1;

    if (this.textureIndex > this.textureCount) {
      this.textureIndex = 0;
    }

    this.material = null;
    this.texture = null;

    this.textureLoader.load(
      `img/${this.textureIndex}.jpg`,
      texture => {
        this.texture = texture;

        this.texture.mapping = THREE.SphericalReflectionMapping;

        this.material = new THREE.MeshBasicMaterial({
          envMap: this.texture
        });

        this.material = new THREE.MeshBasicMaterial({
          envMap: this.texture
        });

        this.skullObject.traverse(child => {
          if (child instanceof THREE.Mesh) {
            child.material = this.material;

            child.position.y = -3.75;
            child.position.z = 2;
            child.position.x = 0.55;

            child.material.needsUpdate = true;
          }
        });
      },
      undefined,
      err => {
        console.error(`Error caught: `, err);
      }
    );
  };

  isDesktop() {
    return window.matchMedia(`(min-width: 1024px)`).matches;
  }

  render() {
    return (
      <div
        className={`skull full-fixed z-1 gpu cursor-pointer ${
          this.layoutContainer.state.bioActive ? `panned` : ``
        }`}
        onClick={this.shuffleTexture}
      >
        <div ref={this.canvasContainer} className="relative gpu" />
      </div>
    );
  }
}

export default Skull;
