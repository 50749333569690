import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Mailer from "./Mailer";
import Skull from "./Skull";

class Home extends Component {
  layoutContainer = this.props.layoutContainer;

  componentDidMount() {
    document.addEventListener(`keydown`, this.handleEsc, false);
  }

  handleEsc = event => {
    if (event.keyCode === 27) {
      this.layoutContainer.hideBio();
    }
  };

  render() {
    return (
      <main
        className={`relative ${
          this.layoutContainer.state.bioActive ? `` : `no-overflow`
        }`}
      >
        <Header layoutContainer={this.layoutContainer} />
        <Skull layoutContainer={this.layoutContainer} />
        <Mailer layoutContainer={this.layoutContainer} />
        <Footer layoutContainer={this.layoutContainer} />

        <div
          className={`home vw-100 vh-100 relative z-2 gpu white ${
            this.layoutContainer.state.bioActive ? `bio-active` : ``
          }`}
        >
          <div
            className={`home__bio relative gpu z-3 pin-t pin-r ${
              this.layoutContainer.state.bioActive ? `active` : ``
            }`}
          >
            <div className="home__bio__inner relative grid gpu">
              <div className="home__bio__text grid__colend--6 grid__colend--sm-12 grid__start--6 grid__start--sm-1 relative gpu">
                <p className="f2">
                  is a strategist, designer, writer, and businessperson. He
                  doesn’t like talking about himself in the third person, but he
                  understands that these bios sound more authoritative when they
                  do, so.
                </p>
                <p className="f2">
                  He works with clients all over the world trying to solve
                  business problems with creative ideas, clear writing, and good
                  design. He loves original answers to complex problems. Charl’s
                  constantly learning about starting, running, and changing
                  businesses. He feels this gives him a slightly more considered
                  approach than a lot of other “creative” types.
                </p>
                <p className="f2">
                  Charl is the creative director and owner of{` `}
                  <a
                    className="underline"
                    href="https://loveandmoney.agency"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Love + Money</span>
                  </a>
                  , a digital branding agency, that for the last 7 years has
                  been building digital identities for the digital age. While he
                  never thought he’d enjoy leading and teaching a team of
                  people, it brings him an incredible amount of joy.
                </p>
                <p className="f2">
                  Two or so years ago (depending on when you’re reading this)
                  Charl co-founded {` `}
                  <a
                    className="underline"
                    href="https://itsallfluff.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Fluff Casual Cosmetics™</span>
                  </a>
                  {` `}, which he believes is the future of the beauty industry.
                  Because if the message we’re sending half the world is that
                  they should cover up who they are, that makes for a pretty
                  fucked up world.
                </p>
                <p className="f2">
                  In past lives he’s worked in branding and advertising for
                  Interbrand, DDB and TBWA, and once wrote a column on feminist
                  issues for The New Zealand Herald. He likes cooking almost as
                  much as he like eating. He still gets up and practices his
                  scales on his guitars every morning. His mum is his hero.
                </p>
                <p className="f2">
                  If you want to hear his thoughts on creativity, business and
                  the internet, he’s publishing them {` `}
                  <a
                    className="underline"
                    href="https://medium.com/@charliehoers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>here</span>
                  </a>
                  . You can follow him on {` `}
                  <a
                    className="underline"
                    href="https://instagram.com/charliehoers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Instagram</span>
                  </a>
                  {` `}
                  if you want, but it’s probably not worth it. If you want to
                  work with him, talk to him, or have him on your panel or
                  podcast, he’d surely be delighted.
                </p>
                <p className="f2">
                  {` `}
                  <a
                    className="underline"
                    href="mailto:charl@loveandmoney.agency"
                  >
                    <span>Get in touch.</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Home;
