import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Provider, Subscribe } from "unstated";
import LayoutContainer from "./state/LayoutContainer";
import Home from "./components/Home";

class App extends Component {
  render() {
    return (
      <Provider>
        <Subscribe to={[LayoutContainer]}>
          {layoutContainer => (
            <main className="App">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => <Home layoutContainer={layoutContainer} />}
                />
              </Switch>
            </main>
          )}
        </Subscribe>
      </Provider>
    );
  }
}

export default App;
